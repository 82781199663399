.home-banner .title {
  background-image: linear-gradient(180deg, #fff 0%, #aaa 49.77%, #fff 100%);
}

@media screen and (max-width: 767px) {
  .home-banner {
    height: 550px;
  }
}

@media screen and (min-width: 768px) {
  .home-banner {
    min-height: 100vh;
    min-height: 100lvh !important;
  }
}

.partner-tile {
  background-image: radial-gradient(circle at center, transparent 65%, #fff0ce);
}
